import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface FormState {
  [key: string]: any;
}

const initialState: FormState = {}

const startupFormResultSlice = createSlice({
  name: 'startupFormResult',
  initialState,
  reducers: {
    addToStartupFormData(state, action: PayloadAction<{ key: string; value: any }>) {
      state[action.payload.key] = action.payload.value
    },
    setStartupFormData: (state, action: PayloadAction<FormState>) => {
      return { ...action.payload }
    },
    addMultipleToStartupFormData(state, action: PayloadAction<FormState>) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key]
      })
    }
  }
})

export default startupFormResultSlice.reducer

export const { addToStartupFormData, setStartupFormData, addMultipleToStartupFormData } = startupFormResultSlice.actions
