import { FC } from 'react'
import SelectRoleItem from './select-role-item/select-role-item'
import { homeText } from '../../utils/constants'
import styles from './select-role.module.css'

const SelectRole: FC = () => {
  return (
    <section className={styles.section}>
      {homeText.map(({ title, link, description }) => (
        <SelectRoleItem
          key={title}
          title={title}
          link={link}
          description={description}
        />
      ))}
    </section>
  )
}

export default SelectRole
