import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  letsStartYourStartupTitle as arrTitle,
  routes,
} from '../../utils/constants'
import CircleForNumbers from '../../ui-kit/CircleForNumbers/CircleForNumbers'
import ActionButton from '../../components/actionButton/ActionButton'

const LetsStartYourStartup: FC = () => {
  const navigate = useNavigate()

  const handleFormSubmit = () => {
    //TODO
    navigate(routes.yourStartup)
  }

  return (
    <section
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <h1 style={{ marginTop: '36px' }}>Let's start your startup</h1>
      <p style={{ margin: '16px 20px 32px', textAlign: 'left' }}>
        In just a few steps, you'll define the core elements of your startup,
        including your mission, product idea, market strategy, and team needs.
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginBottom: '84px',
        }}
      >
        {arrTitle.map((title, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <CircleForNumbers num={index + 1} />
            <h2>{title}</h2>
          </div>
        ))}
      </div>
      <ActionButton handleClick={handleFormSubmit} />
    </section>
  )
}

export default LetsStartYourStartup
