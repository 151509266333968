import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { httpMethods } from '../../../api/utils'
import { FormConfig } from '../../../utils/interfaces'

interface PageData {
  yourStartupForm?: FormConfig;
  yourProductForm?: FormConfig;

  [key: string]: any;
}

interface DataState {
  data: {
    pages?: PageData;
  } | null;
  loading: boolean;
  error: string | null;
}

const initialState: DataState = {
  data: null,
  loading: false,
  error: null
}

export const fetchData = createAsyncThunk(
  'data/fetchData',
  async (endpoint: string, thunkAPI) => {
    try {
      const response = await httpMethods.get(endpoint)
      return { pages: response.data.pages }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong')
    }
  })

const startupFormDataSlice = createSlice({
  name: 'startupFormData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchData.fulfilled, (state, action: PayloadAction<{ pages: PageData }>) => {
        state.loading = false
        state.data = action.payload // Сохраняем только pages
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  }
})

export default startupFormDataSlice.reducer
