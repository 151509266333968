import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/constants'
import Form from '../../components/form/Form'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../app/store'
import { flattenObject } from '../../utils/flattenObject'
import { addMultipleToStartupFormData } from '../../features/startupForm/slices/startupFormResultSlice'

const YourMarket: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const yourMarketForm = useSelector(
    (state: RootState) => state.startupFormData.data?.pages?.yourMarketForm
  )

  if (!yourMarketForm) return <div>Form configuration is missing</div>

  const handleFormSubmit = (data: any) => {
    console.log('Данные формы:', data)
    const flattenedData = flattenObject(data)
    dispatch(addMultipleToStartupFormData(flattenedData))
    navigate(routes.yourStartupLogo)
  }

  return (
    <section>
      <h1 style={{ marginTop: '20px' }}>Your Market</h1>
      <p style={{ margin: '16px 0 24px', fontSize: '12px' }}>
        Identify your competition by providing the URLs of two key competitors
        and highlight what sets your startup apart by detailing your unique
        differentiator. This will clarify your position in the market.
      </p>
      <Form config={yourMarketForm} onSubmit={handleFormSubmit} />
    </section>
  )
}

export default YourMarket
