import React from 'react'
import { FormValues, TextFieldProps } from '../../utils/interfaces'
import { HookTextField, useHookFormContext } from 'mui-react-hook-form-plus'

const Textarea: React.FC<TextFieldProps> = ({ id, placeholder, required }) => {
  const { registerState } = useHookFormContext<FormValues>()

  const labelWithAsterisk = (
    <>
      {placeholder} {required && <span style={{ color: 'red' }}>*</span>}
    </>
  )

  return (
    <HookTextField
      {...registerState(`textareas.${id}`)}
      textFieldProps={{
        label: labelWithAsterisk,
        multiline: true,
        sx: {
          '& .MuiInputLabel-root': {
            color: '#273270'
          },
          '& .MuiOutlinedInput-root': {
            height: '100px',
            display: 'flex',
            flexDirection: 'column'
          },
          '& .MuiOutlinedInput-input': {
            height: '100%',
            overflow: 'auto'
          }
        }
      }}
      rules={{
        required: {
          value: required,
          message: 'A required field'
        }
      }}
    />
  )
}

export default Textarea

