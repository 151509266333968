import React from 'react'
import LogoRectangle from '../../components/logoRectangle/LogoRectangle'
import ItemStartupTeam from '../../components/itemStartupTeam/ItemStartupTeam'
import ActionButton from '../../components/actionButton/ActionButton'

const StartupTeamCoFounder = () => {
  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          marginTop: '20px',
          width: '100%',
          gap: '35px',
          marginBottom: '24px',
        }}
      >
        <h1>Startup Team</h1>
        <LogoRectangle logo={'logo'} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '12px',
          width: '100%',
          paddingLeft: '20px',
        }}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <ItemStartupTeam
            key={index}
            title={'Founder Name'}
            subtitle={'Product Management'}
            button={false}
          />
        ))}
      </div>

      <ActionButton text={'Back'} />
    </section>
  )
}

export default StartupTeamCoFounder
