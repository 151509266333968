import React, { useMemo } from 'react'
import { HookFormProvider, useHookForm } from 'mui-react-hook-form-plus'
import Stack from '@mui/material/Stack'
import { FormProps, FormValues } from '../../utils/interfaces'
import ActionButton from '../actionButton/ActionButton'
import MultiSelect from '../multiSelect/MultiSelect'
import Select from '../select/Select'
import TextField from '../TextField/TextField'
import Textarea from '../Textarea/Textarea'

const Form: React.FC<FormProps> = ({ config, onSubmit, textButton }) => {
  const defaultValues = useMemo(() => {
    const inputs = config.fields
      .filter(field => field.type === 'text') // Фильтруем только type: 'text'
      .reduce((acc, field) => {
        acc[field.id] = '' // Создаем ключ из id, а значение — пустая строка
        return acc
      }, {} as Record<string, string>)

    const textareas = config.fields
      .filter(field => field.type === 'textarea') // Фильтруем только type: 'text'
      .reduce((acc, field) => {
        acc[field.id] = '' // Создаем ключ из id, а значение — пустая строка
        return acc
      }, {} as Record<string, string>)

    const selects = config.fields
      .filter(field => field.type === 'select') // Фильтруем только type: 'text'
      .reduce((acc, field) => {
        acc[field.id] = '' // Создаем ключ из id, а значение — пустая строка
        return acc
      }, {} as Record<string, string>)

    const multiselects = config.fields
      .filter(field => field.type === 'multiselect')
      .reduce((acc, field) => {
        acc[field.id] = [] // Пустой массив вместо пустой строки
        return acc
      }, {} as Record<string, string[]>)

    return {
      inputs,
      textareas,
      selects,
      multiselects
    }
  }, [config])

  const methods = useHookForm<FormValues>({
    defaultValues
  })

  const { registerState, handleSubmit, reset, control, getValues, setValues } = methods

  const handleFormSubmit = (data: typeof defaultValues) => {
    onSubmit(data)
    reset()
  }

  if (!config) {
    return <div>Конфигурация формы отсутствует</div>
  }

  return (
    <HookFormProvider {...methods}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="20px" marginBottom={'50px'} width="335px">
          {/* text */}
          {config.fields.filter(field => field.type === 'text').map((input) => (
            <TextField
              key={input.id}
              id={input.id}
              placeholder={input.placeholder}
              required={input.required}
            />
          ))}

          {/* textarea */}
          {config.fields.filter(field => field.type === 'textarea').map((input) => (
            <Textarea
              key={input.id}
              id={input.id}
              placeholder={input.placeholder}
              required={input.required}
            />
          ))}

          {/* select */}
          {config.fields.filter(field => field.type === 'select').map((input) => (
            <Select
              key={input.id}
              id={input.id}
              placeholder={input.placeholder}
              required={input.required}
              options={input.options || []}
            />
          ))}

          {/* multiselect */}
          {config.fields.filter(field => field.type === 'multiselect').map((input) => (
            <MultiSelect
              key={input.id}
              id={input.id}
              placeholder={input.placeholder}
              required={input.required}
              options={input.options || []}
            />
          ))}
        </Stack>
        <ActionButton text={textButton} />
      </form>
    </HookFormProvider>
  )
}

export default Form
