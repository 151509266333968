import { FC } from 'react'
import { Box } from '@mui/material'

const colors = ['#273270', '#39468D', '#4556B2', '#5262BB', '#5969C0']

const ColoredCircles: FC<{ count?: number }> = ({ count = 5 }) => {
  const maxCircles = Math.min(count, colors.length)

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      height="40px"
    >
      {Array.from({ length: maxCircles }).map((_, index) => (
        <Box
          key={index}
          bgcolor={colors[maxCircles - 1 - index]}
          width="40px"
          height="40px"
          borderRadius="50%"
          position="absolute"
          left={`${(maxCircles - 1 - index) * 10}px`}
        />
      ))}
    </Box>
  )
}

export default ColoredCircles
