import React from 'react'
import { HookSelect, useHookFormContext } from 'mui-react-hook-form-plus'
import { Typography } from '@mui/material'
import { FormValues, SelectProps } from '../../utils/interfaces'
import { ReactComponent as CustomArrowIcon } from '../select/Arrow-Down.svg'

const MultiSelect: React.FC<SelectProps> = ({ id, placeholder, required, options }) => {
  const { registerState } = useHookFormContext<FormValues>()
  const isDisabled = options.length === 0

  const labelWithAsterisk = (
    <>
      {placeholder} {required && <span style={{ color: 'red' }}>*</span>}
    </>
  )

  return (
    <HookSelect
      {...registerState(`multiselects.${id}`)}
      label={labelWithAsterisk}
      selectProps={{
        multiple: true,
        disabled: isDisabled,
        IconComponent: CustomArrowIcon,
        sx: {
          '& .MuiInputLabel-root': {
            color: '#273270'
          },
          '& .MuiOutlinedInput-root': {
            height: '60px'
          }
        }
      }}
      items={options}
      renderItem={({ label }) => {
        return <Typography variant="caption">{label}</Typography>
      }}
      gridProps={{
        xs: 6,
        md: 4
      }}
      rules={{
        required: {
          value: required,
          message: 'Please select at least one'
        }
      }}
    />
  )
}

export default MultiSelect
