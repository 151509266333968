import React from 'react'
import LogoRectangle from '../../components/logoRectangle/LogoRectangle'
import ItemStartupTeam from '../../components/itemStartupTeam/ItemStartupTeam'
import ActionButton from '../../components/actionButton/ActionButton'

const StartupTeam = () => {
  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '20px',
          width: '100%',
          gap: '35px',
          marginBottom: '24px',
        }}
      >
        <LogoRectangle logo={'logo'} />
        <h1>Startup Team</h1>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '12px',
          paddingLeft: '10px',
          width: '310px',
        }}
      >
        <ItemStartupTeam
          title={'Founder Name'}
          subtitle={'Product Management'}
          button={false}
        />

        {Array.from({ length: 5 }).map((_, index) => (
          <ItemStartupTeam
            key={index}
            title={'Co-Founder name'}
            subtitle={'Software Development'}
            button={true}
          />
        ))}
      </div>

      <ActionButton text={'Back'} />
    </section>
  )
}

export default StartupTeam
