import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import Form from '../../components/form/Form'
import AddImage from '../../components/addImage/AddImage'
import { FormConfig } from '../../utils/interfaces'
import { routes } from '../../utils/constants'

const Register = () => {
  const navigate = useNavigate()
  const config: FormConfig = {
    fields: [
      {
        id: 'firstname',
        type: 'text',
        placeholder: 'First Name',
        required: true
      },
      {
        id: 'lastname',
        type: 'text',
        placeholder: 'Last Name',
        required: true
      },
      {
        id: 'email',
        type: 'text',
        placeholder: 'Email',
        required: true
      }
    ]
  }

  const handleFormSubmit = (data: any) => {
    console.log('Данные формы:', data)
    navigate(routes.selectRole)
  }

  return (
    <Box marginTop="76px">
      <AddImage type="avatar" />
      <Form config={config} onSubmit={handleFormSubmit} textButton="Register" />
      <Typography
        align="left"
        color="text.secondary"
        width="311px"
        margin="auto"
        paddingTop="12px"
        sx={{
          color: '#21242b',
          fontFamily: '\'SF Pro Text\', \'Roboto\', sans-serif',
          fontSize: '10px',
          fontWeight: 400,
          lineHeight: '10px'
        }}
      >
        By clicking "Registration" I confirm that I have read and agree to the
        privacy policy, cookie processing policy, accept the user agreement and
        consent to the processing of my personal data in accordance with the
        consent to the processing of personal data.
      </Typography>
    </Box>
  )
}

export default Register
