import { FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import styles from './header.module.css'
const Header: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const registerPage = location.pathname !== '/'

  return (
    <header className={styles.header}>
      {registerPage && (
        <button className={styles.header__back} onClick={() => navigate(-1)} />
      )}
    </header>
  )
}
export default Header
