import { FC } from 'react'

type Props = {
  size?: 'big'
  background?: boolean
  logo: any
}

const LogoRectangle: FC<Props> = ({ size, background, logo }) => {
  if (size && background) {
    background = false
  }

  const width = size ? '100px' : '60px'
  const height = size ? '83px' : '50px'

  const circleStyle = {
    width: '76px',
    height: '76px',
    borderRadius: '50%',
    backgroundColor: background ? 'var(--lightBlue)' : 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

  return (
    <div style={background ? circleStyle : {}}>
      <div
        style={{
          width: width,
          height: height,
          border: '4px solid var(--blue)',
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          boxShadow: 'var(--boxShadow)',
          backgroundImage: `url(${logo})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          objectFit: 'cover'
        }}
      />
    </div>
  )
}

export default LogoRectangle
