import { FC } from 'react'
import styles from './itemStartupTeam.module.css'

type Props = {
  size?: 'small'
  color?: 'blue'
  title?: string
  subtitle?: string
  button?: boolean
}

const ItemStartupTeam: FC<Props> = ({
  size,
  color,
  title,
  subtitle,
  button,
}) => {
  const collorMap = new Map([
    ['lightBlue', styles.lightBlue_circle],
    ['blue', styles.blue_circle],
  ])

  const sizeMap = new Map([['small', styles.small_circle]])

  const circleSize = size ? sizeMap.get(size) : ''

  const circleColor = color ? collorMap.get(color) : collorMap.get('lightBlue')

  const handleDelete = () => {
    console.log('delete')
  }

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={`${circleColor} ${circleSize}`}></div>
        <div className={styles.article}>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
      </div>
      {button && (
        <button onClick={handleDelete} className={styles.close}>
          <img src="/icons/close.svg" alt="close" />
        </button>
      )}
    </div>
  )
}

export default ItemStartupTeam
