import React from 'react'
import { Box, Typography } from '@mui/material'

const Loader = () => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '100vh',
      maxWidth: '375px',
      width: '100%',
      boxSizing: 'border-box',
      padding: '0 20px',
      marginTop: '156px'
    }}>
      <Box
        component="img"
        src="/logo-loader.png"
        alt="Loader"
        sx={{
          width: 150,
          height: 'auto',
          marginBottom: '166px'
        }}
      />
      <Typography sx={{
        color: '#21242b',
        fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '20px'
      }}>Loading ...</Typography>
    </Box>
  )
}

export default Loader
