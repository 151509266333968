import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/constants'
import ActionButton from '../../components/actionButton/ActionButton'
import NextStepStartupTeam from '../../components/nextStepStartupTeam/NextStepStartupTeam'
import ColoredCircles from '../../ui-kit/ColoredCircles/ColoredCircles'
import LogoRectangle from '../../components/logoRectangle/LogoRectangle'

const StartupNameFoundersTeam: FC = () => {
  const navigate = useNavigate()

  const handleFormSubmit = () => {
    //TODO
    navigate(routes.startupTeam)
  }

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
          width: '100%',
        }}
      >
        <LogoRectangle logo={'logo'} />
        <h1>Startup Name</h1>
        <img src="/icons/pencil.svg" alt="pencil" />
      </div>
      <NextStepStartupTeam step={1} />
      <h2>Founders Team</h2>
      <p>To launch your startup your team need to have at least 3 Founders</p>
      <div style={{ paddingRight: '50px' }}>
        <ColoredCircles count={3} />
      </div>
      <ActionButton
        text="Startup Team"
        color="green"
        handleClick={handleFormSubmit}
      />
      <ActionButton text="Startup Token" color="lightBlue" />
      <ActionButton text="Startup Track" color="lightBlue" />
    </section>
  )
}

export default StartupNameFoundersTeam
