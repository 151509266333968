import React from 'react'
import LogoWithCircles from '../../components/logoWithCircles/LogoWithCircles'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/constants'
import ActionButton from '../../components/actionButton/ActionButton'

import { SendTransactionRequest, TonConnectButton, useTonConnectUI } from '@tonconnect/ui-react'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'

const Dashboard = () => {
  const navigate = useNavigate()

  const handleFormSubmit = () => {
    //TODO
    navigate(routes.startupNameFoundersTeam)
  }

  const transaction: SendTransactionRequest = {
    validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
    messages: [
      {
        address: 'UQCmHU39chg1_j_r9CAjG9wg0-G3o2W9OztdVS-EiXWHFMz-', // message destination in user-friendly format
        amount: '1' // Toncoin in nanotons
      }
    ]
  }

  const [tonConnectUI, setOptions] = useTonConnectUI()

  const typographyStyles = {
    fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.09px'
  }

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px'
      }}
    >
      <h1>Dashboard</h1>
      <div onClick={handleFormSubmit} style={{ cursor: 'pointer' }}>
        <LogoWithCircles name="Startup name" industry="Industry"
                         description="Startup Short Description. Startup Short Description. Startup Short Description." />
      </div>
      <img src="/Startup-close-card.png" alt="Your Startup " />
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <TonConnectButton />
        <Button
          type="button"
          onClick={() => tonConnectUI.sendTransaction(transaction)}
          variant="contained"
          sx={{
            height: '40px',
            width: '162px',
            textTransform: 'none',
            color: '#fff',
            background: 'linear-gradient(176deg, #494c8c 1%, #273270 51.5%, #0f134f 100%)',
            boxShadow: '1px 3px 3px 0 rgba(28, 15, 79, 0.24)',
            typography: typographyStyles,
            borderRadius: '30px',
            padding: '12px 0px'
          }}
        >
          Send transaction
        </Button>
      </Box>
      <ActionButton
        text={'Launch Startup'}
        colorButton="rgba(15, 19, 79, 0.24)"
      />
    </section>
  )
}

export default Dashboard
