import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/constants'
import Form from '../../components/form/Form'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../app/store'
import { flattenObject } from '../../utils/flattenObject'
import { addMultipleToStartupFormData } from '../../features/startupForm/slices/startupFormResultSlice'

const YourCustomerTargetAudience: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const yourCustomerTargetAudienceForm = useSelector(
    (state: RootState) => state.startupFormData.data?.pages?.yourCustomerTargetAudienceForm
  )

  if (!yourCustomerTargetAudienceForm) return <div>Form configuration is missing</div>

  const handleFormSubmit = (data: any) => {
    console.log('Данные формы:', data)
    const flattenedData = flattenObject(data)
    dispatch(addMultipleToStartupFormData(flattenedData))
    navigate(routes.yourCustomer)
  }

  return (
    <section>
      <h1 style={{ marginTop: '20px' }}>Your Customer</h1>
      <p style={{ margin: '16px 0 24px', fontSize: '12px' }}>
        Select the characteristics that best represent your ideal customers.
        Focus on defining who they are, what motivates them, and their
        preferences to ensure your product aligns with their needs and
        expectations.
      </p>
      <h2 style={{ marginBottom: '17px' }}>Target Audience</h2>
      <Form config={yourCustomerTargetAudienceForm} onSubmit={handleFormSubmit} />
    </section>
  )
}

export default YourCustomerTargetAudience
