import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/constants'
import Form from '../../components/form/Form'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../app/store'
import { flattenObject } from '../../utils/flattenObject'
import { addMultipleToStartupFormData } from '../../features/startupForm/slices/startupFormResultSlice'

const YourCustomer: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const yourCustomerForm = useSelector(
    (state: RootState) => state.startupFormData.data?.pages?.yourCustomerForm
  )

  if (!yourCustomerForm) return <div>Form configuration is missing</div>

  const handleFormSubmit = (data: any) => {
    console.log('Данные формы:', data)
    const flattenedData = flattenObject(data)
    dispatch(addMultipleToStartupFormData(flattenedData))
    navigate(routes.yourMarket)
  }

  return (
    <section>
      <h1 style={{ marginTop: '20px' }}>Your Customer</h1>
      <p style={{ margin: '16px 0 24px', fontSize: '12px' }}>
        Define your target audience by specifying their key interests in the
        input field and selecting their gender and geographic location using the
        drop-down lists. This will help refine your focus and align your product
        with their preferences.
      </p>
      <Form config={yourCustomerForm} onSubmit={handleFormSubmit} />
    </section>
  )
}

export default YourCustomer
