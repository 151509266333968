import { createTheme, ThemeOptions } from '@mui/material/styles'

const theme: ThemeOptions = ({
  palette: {
    primary: {
      main: '#273270'
    },
    text: {
      primary: '#273270',
      secondary: '#273270'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px', // Радиус для контейнера
            boxShadow: '1px 4px 4px 0 rgba(15, 19, 79, 0.24)',
            '& fieldset': {
              borderWidth: '4px', // Ширина рамки
              borderColor: '#273270', //Здесь можно менять цвет рамки
              // padding: '0 32px', // Увеличить отступы для создания пространства для legend
              '& legend': {
                color: 'text.primary',
                fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif', // Размер шрифта legend как у label
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '16px',
                maxWidth: '100%'
              }
            },
            '&.Mui-focused fieldset': {
              borderWidth: '4px'
            }
          },
          '& .MuiInputLabel-root': {
            color: 'text.primary',
            opacity: 0.8,
            fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '16px'
            // transform: 'translate(41px, -9px) scale(1)',
          },
          '& .MuiInputBase-input': {
            //height: '60px',
            //padding: '0 20px',
            color: 'text.secondary',
            fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif',
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '20px'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: '1px 4px 4px 0 rgba(15, 19, 79, 0.24)',
          //height: '60px',

          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '4px',
            borderColor: '#273270',

            '& legend': {
              color: 'text.primary',
              fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '16px',
              maxWidth: '100%',
              opacity: 0.8
            }
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.light'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '4px',
            borderColor: 'primary.main'
          },
          '& .MuiSelect-icon': {
            color: 'primary.main'
          }
        },
        icon: {
          right: '24px'
        },

        select: {
          //height: '60px',
          //padding: '0 20px',
          color: 'text.secondary',
          fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif',
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '20px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          textAlign: 'center',

          '& .MuiTypography-root': {
            color: 'text.secondary',
            fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif',
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '20px'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            transform: 'translate(17px, -9px) scale(1)'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiFormLabel-root': {
            color: 'text.primary',
            fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '16px',
            maxWidth: '100%',
            opacity: 0.8
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'text.primary',
          fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif', // Размер шрифта legend как у label
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '16px',
          '& .MuiTypography-root': {
            color: 'text.primary',
            fontFamily: '\'SF Pro Display\', \'Roboto\', sans-serif', // Размер шрифта legend как у label
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '16px'
          }
        }
      }
    }
  }
})

export default createTheme(theme)
