import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import Form from '../../components/form/Form'
import { routes } from '../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../app/store'
import { flattenObject } from '../../utils/flattenObject'
import { addMultipleToStartupFormData } from '../../features/startupForm/slices/startupFormResultSlice'

const YourProduct: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const yourProductForm = useSelector(
    (state: RootState) => state.startupFormData.data?.pages?.yourProductForm
  )

  if (!yourProductForm) return <div>Form configuration is missing</div>

  const handleFormSubmit = (data: any) => {
    console.log('Данные формы:', data)
    const flattenedData = flattenObject(data)
    dispatch(addMultipleToStartupFormData(flattenedData))
    navigate(routes.yourCustomerTargetAudience)
  }

  return (
    <section>
      <h1 style={{ marginTop: '20px' }}>Your Product</h1>
      <p style={{ margin: '16px 0 24px', fontSize: '12px' }}>
        Clearly describe the key problem your product addresses and outline the
        unique solution it offers. Focus on how your solution effectively
        resolves the problem to demonstrate its value to potential users and
        investors.
      </p>
      <Form config={yourProductForm} onSubmit={handleFormSubmit} />
    </section>
  )
}

export default YourProduct
