import { ChangeEvent, FC, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { ReactComponent as PlusIcon } from './plus.svg'
import { useDispatch } from 'react-redux'
import { setRegistrationFormData } from '../../features/registrationForm/slices/registrationFormResultSlice'
import { addMultipleToStartupFormData } from '../../features/startupForm/slices/startupFormResultSlice'

type Props = {
  type?: 'avatar' | 'image';
};

const AddImage: FC<Props> = ({ type = 'image' }) => {
  const [image, setImage] = useState<string | null>(null)
  const dispatch = useDispatch()

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    if ('files' in target && target.files) {
      const file = target.files[0]
      if (file) {
        const imageUrl = URL.createObjectURL(file)
        setImage(imageUrl)

        if (type === 'avatar') {
          dispatch(setRegistrationFormData({ 'avatar_url': imageUrl }))
        } else {
          dispatch(addMultipleToStartupFormData({ 'startup_logo_url': imageUrl }))
        }
      }
    }
  }

  return type === 'avatar' ? (
    <Box
      sx={{
        position: 'relative',
        mb: '64px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          width: '80px',
          height: '80px',
          borderRadius: '100%',
          backgroundColor: image ? 'inherit' : '#273270',
          backgroundImage: image ? `url(${image})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <input
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        id="avatar-upload"
      />
      <label htmlFor="avatar-upload">
        <IconButton
          sx={{
            weight: '18px',
            position: 'absolute',
            bottom: '-4px',
            right: '122px',
            backgroundColor: '#bec7fc',
            color: '#232d6a',
            '&:hover': {
              backgroundColor: '#bec7fc'
            }
          }}
          component="span"
        >
          <PlusIcon />
        </IconButton>
      </label>
    </Box>
  ) : (
    <Box
      sx={{
        margin: '32px auto 50px',
        width: '179px',
        height: '148px',
        border: '4px solid var(--blue)',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        boxShadow: 'var(--boxShadow)'
      }}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        id="image-upload"
      />
      <label htmlFor="image-upload" style={{ width: '100%', height: '100%' }}>
        <IconButton
          component="span"
          style={{
            width: '100%',
            height: '100%',
            padding: 0,
            backgroundColor: 'transparent'
          }}
        >
          {image ? (
            <img
              src={image}
              alt="Uploaded"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          ) : (
            <AddIcon style={{ fontSize: '40px', color: '#273270' }} />
          )}
        </IconButton>
      </label>
    </Box>
  )
}

export default AddImage
