import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import App from './app/app'
import './index.css'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme/theme'
import { Provider } from 'react-redux'
import store from './app/store'

import { TonConnectUIProvider } from '@tonconnect/ui-react'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <TonConnectUIProvider
      manifestUrl="https://raw.githubusercontent.com/5D-HUB/manifest/refs/heads/main/Hat/tonconnect-manifest.json"
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/Hat_5d_bot/myhat'
      }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CssBaseline>
        </ThemeProvider>
      </Provider>
    </TonConnectUIProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
