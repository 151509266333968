import { FC } from 'react'
import CircleForNumbers from '../../ui-kit/CircleForNumbers/CircleForNumbers'
import styles from './nextStepStartupTeam.module.css'

type Props = {
  step: number
}

const NextStepStartupTeam: FC<Props> = ({ step }) => {
  const numArr = [1, 2, 3]

  return (
    <div className={styles.container}>
      {numArr.map((num, index) => (
        <div key={num} className={styles.box}>
          <CircleForNumbers num={num} step={num <= step} />
          {index < numArr.length - 1 && <div className={styles.line} />}
        </div>
      ))}
    </div>
  )
}

export default NextStepStartupTeam
