import React from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import LogoRectangle from '../../components/logoRectangle/LogoRectangle'
import ActionButton from '../../components/actionButton/ActionButton'
import { routes } from '../../utils/constants'

const StartupJoin = () => {
  const navigate = useNavigate()

  const handleStartupJoinRequest = () => {
    //TODO
    navigate(routes.startupJoinRequest)
  }
  return (
    <section
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            marginTop: '20px',
            
        }}
    >
        <h1>Startup Join</h1>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: "flex-start",
                gap: '12px',
                width: '295px'
            }}
        >
            <LogoRectangle logo={'logo'} />
            <h2>Startup name</h2>
        </div>
        <p 
            style={{
                width: '295px',
                textAlign: 'left',
                fontSize: '16px',
                lineHeight: '19px',
            }}
        >
            You request to join the &lt;Startup <br /> Name&gt; as a Co-Founder on a &lt;Position&gt; position, dated <br />at October 29th, 2024
        </p>
        <h4
            style={{
                width: '295px',
                fontSize: '20px',
                lineHeight: '23.87px',
                marginBottom: '123px'
            }}
        > 
            Would you like to proceed and send this request to Startup Founder? 
        </h4>
        <ActionButton 
            text={'Request'} 
            handleClick={handleStartupJoinRequest} 
        />
    </section>
  )
}

export default StartupJoin


