import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/constants'
import AddImage from '../../components/addImage/AddImage'
import ActionButton from '../../components/actionButton/ActionButton'

const YourStartupLogo: FC = () => {
  const navigate = useNavigate()

  const handleFormSubmit = () => {
    //TODO
    navigate(routes.yourStartupPitch)
  }
  return (
    <section>
      <h1 style={{ marginTop: '36px' }}>Your Startup</h1>
      <h2 style={{ margin: '34px 65px 12px' }}>
        Upload Your Startup Icon or Logo
      </h2>
      <p>
        Choose an image that best represents your startup. This will be
        displayed in search results, team member onboarding, and your startup
        profile.
      </p>
      <AddImage />
      <ActionButton text="Launch it!" handleClick={handleFormSubmit} />
    </section>
  )
}

export default YourStartupLogo
