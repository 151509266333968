import { FC } from 'react'
import RoundButton from '../../../components/roundButton/RoundButton'
import styles from './select-role-item.module.css'

type Props = {
  title: string
  description: string
  link: string
}

const SelectRoleItem: FC<Props> = ({ title, description, link }) => {
  return (
    <div className={styles.item}>
      <h1 className={styles.box}>
        {title}
        <RoundButton link={link} />
      </h1>
      <p className={styles.description}>{description}</p>
      <div className={styles.line__box}>
        <div className={styles.line}></div>
        <p className={styles.or}>or</p>
        <div className={styles.line}></div>
      </div>
    </div>
  )
}

export default SelectRoleItem
