import React from 'react'
import { FormValues, TextFieldProps } from '../../utils/interfaces'
import { HookTextField, useHookFormContext } from 'mui-react-hook-form-plus'

const TextField: React.FC<TextFieldProps> = ({ id, placeholder, required }) => {
  const { registerState } = useHookFormContext<FormValues>()

  const labelWithAsterisk = (
    <>
      {placeholder} {required && <span style={{ color: 'red' }}>*</span>}
    </>
  )

  return (
    <HookTextField
      {...registerState(`inputs.${id}`)}
      textFieldProps={{
        label: labelWithAsterisk,
        sx: {
          '& .MuiInputLabel-root': {
            color: '#273270'
          },
          '& .MuiOutlinedInput-root': {
            height: '60px'
          }
        }
      }}
      rules={{
        required: {
          value: required,
          message: 'A required field'
        }
      }}
    />
  )
}

export default TextField
