import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'

type Props = {
  color?: 'lightBlue' | 'green' | 'pink'
  colorButton?: any
  size?: 'small'
  text?: string
  type?: 'button' | 'submit'
  handleClick?: () => void
}

const ActionButton: FC<Props> = ({
  color,
  colorButton,
  size,
  text = 'Next',
  type = 'submit',
  handleClick,
}) => {
  const location = useLocation()

  const textColor = color ? 'var(--blue)' : ''

  const buttonWidth = size ? 124 : 275

  const typographyStyles = {
    fontFamily: "'SF Pro Display', 'Roboto', sans-serif",
    fontSize: size ? '16px' : '20px',
    fontWeight: 600,
    lineHeight: size ? '19.09px' : '23.87px',
  }

  const fillPercent =
    location.pathname === '/founder'
      ? 12
      : location.pathname === '/lets-start-your-startup'
        ? 24
        : location.pathname === '/your-startup'
          ? 36
          : location.pathname === '/your-product'
            ? 40
            : location.pathname === '/your-customer-target-audience'
              ? 60
              : location.pathname === '/your-customer'
                ? 72
                : location.pathname === '/your-market'
                  ? 84
                  : 0

  const textColorFill = fillPercent && fillPercent < 50 ? 'var(--blue)' : ''

  const buttonTextColor = color ? textColor : textColorFill

  const buttonLightBlue = 'var(--lightBlue)'

  const buttonBlue = 'var(--blue)'

  const buttonFillColor = `linear-gradient(to right, ${buttonBlue} ${fillPercent}%, ${buttonLightBlue} ${fillPercent}%)`

  const buttonBackground =
    color === 'lightBlue'
      ? 'var(--lightBlue)'
      : color === 'green'
        ? 'var(--green)'
        : color === 'pink'
          ? 'var(--pink)'
          : 'var(--blue)'

  const buttonColor = fillPercent ? buttonFillColor : buttonBackground

  return (
    <Button
      type={type}
      onClick={handleClick}
      variant="contained"
      sx={{
        height: 60,
        width: buttonWidth,
        textTransform: 'none',
        color: buttonTextColor,
        background: colorButton ? colorButton : buttonColor,
        boxShadow: 'var(--boxShadow)',
        typography: typographyStyles,
        borderRadius: '12px',
        padding: '12px 4px',
      }}
    >
      {text}
    </Button>
  )
}

export default ActionButton
