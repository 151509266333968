import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Form from '../../components/form/Form'
import { routes } from '../../utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../../features/startupForm/slices/startupFormDataSlice'
import { AppDispatch, RootState } from '../../app/store'
import { flattenObject } from '../../utils/flattenObject'
import { addMultipleToStartupFormData } from '../../features/startupForm/slices/startupFormResultSlice'

const YourStartup = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const yourStartupForm = useSelector(
    (state: RootState) => state.startupFormData.data?.pages?.yourStartupForm
  )
  const loading = useSelector((state: RootState) => state.startupFormData.loading)
  const error = useSelector((state: RootState) => state.startupFormData.error)

  useEffect(() => {
    if (!yourStartupForm) {
      dispatch(fetchData('/startups/startup_form'))
    }
  }, [dispatch, yourStartupForm])

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>
  if (!yourStartupForm) return <div>Form configuration is missing</div>

  const handleFormSubmit = (data: any) => {
    console.log('Данные формы:', data)
    const flattenedData = flattenObject(data)
    dispatch(addMultipleToStartupFormData(flattenedData))
    navigate(routes.yourProduct)
  }

  return (
    <section>
      <h1 style={{ marginTop: '20px' }}>Your Startup</h1>
      <p style={{ margin: '16px 0 24px', fontSize: '12px' }}>
        Provide the name of your startup and a concise description that
        highlights its core idea or mission. Then, select your startup’s
        location, industry, and business model from the drop-down lists to
        ensure your startup profile is clear and aligned with potential
        investors and collaborators.
      </p>
      <Form config={yourStartupForm} onSubmit={handleFormSubmit} />
    </section>
  )
}

export default YourStartup
