import React from 'react';
import style from './startup-list-filter.module.css';
import ActionButton from '../../../components/actionButton/ActionButton';
import { filterItems } from '../../../utils/constants';

interface FilterItem {
  name: string;
  statusItem: boolean;
}

interface StartupListFilterProps {
  handleFilter: () => void;
}

const StartupListFilter: React.FC<StartupListFilterProps> = ({ handleFilter }) => {
  return (
    <section className={style.container}>
      <div className={style.header}>
        <img
          className={style.filterClose}
          onClick={handleFilter}
          src="./icons/filter-close.svg"
          alt="Close"
        />
        <h1>Filters</h1>
        <button className={style.reset_btn}>Reset</button>
      </div>

      <div className={style.btns}>
        <button>Industry</button>
        <button>Language</button>
        <button>Position</button>
      </div>

      <div className={style.filterItems}>
        {filterItems.map((item: FilterItem, index: number) => (
          <div key={index} className={style.filterItem}>
            <p>{item.name}</p>
            <img
              src={item.statusItem ? "./icons/startup-filter-checked.svg"  : "./icons/startup-filter-notchecked.svg" }
              alt={item.statusItem ? 'Filtered' : 'Not filtered'}
            />
          </div>
        ))}
      </div>

      <ActionButton text="Confirm" handleClick={handleFilter} />
    </section>
  );
};

export default StartupListFilter;
