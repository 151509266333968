import React from 'react'
import { styled } from '@mui/material/styles'
import LogoRectangle from '../../components/logoRectangle/LogoRectangle'
import ActionButton from '../../components/actionButton/ActionButton'

const StartupJoinRequest = () => {

  return (
    <section
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            marginTop: '20px',
            
        }}
    >
        <h1>Startup Join</h1>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: "flex-start",
                gap: '12px',
                width: '295px'
            }}
        >
            <LogoRectangle logo={'logo'} />
            <h2>Startup name</h2>
        </div>
        <h4
            style={{
                width: '295px',
                fontSize: '20px',
                lineHeight: '23.87px',
                marginBottom: '201px'
            }}
        > 
            Thank you for interest. Startup Founders will review your request and get back 
            to you shortly
        </h4>
        <ActionButton 
            text={'Close'} 
        />
    </section>
  )
}

export default StartupJoinRequest;