export const routes = {
  home: '/',
  selectRole: '/select-role',
  founder: '/founder',
  letsStartYourStartup: '/lets-start-your-startup',
  yourStartup: '/your-startup',
  yourProduct: '/your-product',
  yourCustomerTargetAudience: '/your-customer-target-audience',
  yourCustomer: '/your-customer',
  yourMarket: '/your-market',
  yourStartupLogo: '/your-startup-logo',
  yourStartupPitch: '/your-startup-pitch',
  startupNameFoundersTeam: '/startup-name-founders-team',
  startupTeam: '/startup-team',
  dashboard: '/dashboard',
  coFounder: '/co-founder',
  letMeFindStartup: '/let-me-find-startup',
  startupList: '/startup-list',
  startupName: '/startup-list/name',
  startupTeamCoFounder: '/startup-list/name/team',
  coBuilder: '/co-builder',
  startupJoin: '/startup-list/name/join',
  startupJoinRequest: '/startup-list/name/join/request'
}

export const homeText = [
  {
    link: routes.founder,
    title: 'Founder',
    description:
      'Your path to your own startup starts here. If you have an idea for your own startup, regardless of the stage of implementation, our platform will help bring it to life. Start your path to success now!',
  },
  {
    link: routes.coFounder,
    title: 'Co-founder',
    description:
      'Discover inspiring startups, connect with visionaries, and find the team where your skills will shine. Your new adventure begins here!',
  },
  {
    link: routes.coBuilder,
    title: 'Co-builder',
    description:
      "Your journey to join innovative startups begins here. Whether you're a developer, marketer, designer, or strategist, our platform connects you with exciting projects tailored to your expertise. Let’s get started and find your next big challenge.",
  },
]

export const letsStartYourStartupTitle = [
  'Product Vision',
  'Market',
  'Business Model',
]

export const filterItems  = [
  {
      name: 'All positions',
      statusItem: true
  },
  {
      name: 'Product Manager',
      statusItem: true
  },
  {
      name: 'Project Manager',
      statusItem: true
  },
  {
      name: 'UX/UI Designer',
      statusItem: false
  },
  {
      name: 'Sales Manager',
      statusItem: true
  },
  {
      name: 'Marketing Manager',
      statusItem: false
  },
  {
      name: 'Financial Analyst',
      statusItem: true
  },
  {
      name: 'BizDev Manager',
      statusItem: true
  },
  {
      name: 'Frontend Developer',
      statusItem: false
  },
  {
      name: 'Backend Developer',
      statusItem: true
  },
]