import { FC } from 'react'
import ColoredCircles from '../../ui-kit/ColoredCircles/ColoredCircles'
import LogoRectangle from '../logoRectangle/LogoRectangle'

type Props = {
  //TODO delete ?
  name?: string
  industry?: string
  description?: string
  url?: string
}

const LogoWithCircles: FC<Props> = ({ name, industry, description, url }) => {
  return (
    <article
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'var(--lightBlue)',
        gap: '12px',
        borderRadius: '12px',
        padding: '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '12px',
        }}
      >
        <LogoRectangle logo={url ?? ''} />
        <div style={{ textAlign: 'left' }}>
          <h2>{name}</h2>
          <p>{industry}</p>
        </div>
      </div>
      <p style={{ textAlign: 'left', paddingRight: '30px' }}>{description}</p>
      <ColoredCircles />
    </article>
  )
}

export default LogoWithCircles
