import { JSX, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Founder from '../pages/founder/founder'
import Header from '../components/header/Header'
import Register from '../pages/register/register'
import CoFunder from '../pages/co-founder/co-founder'
import CoBuilder from '../pages/co-builder/co-builder'
import SelectRole from '../pages/select-role/select-role'
import YourStartup from '../pages/your-startup/your-startup'
import YourProduct from '../pages/your-product/your-product'
import YourStartupLogo from '../pages/your-startup-logo/your-startup-logo'
import LetsStartYourStartup from '../pages/lets-start-your-startup/lets-start-your-startup'
import YourCustomerTargetAudience from '../pages/your-customer-target-audience/your-customer-target-audience'
import YourCustomer from '../pages/your-customer/your-customer'
import YourMarket from '../pages/your-market/your-market'
import YourSturtupPitch from '../pages/your-startup-pitch/your-startup-pitch'
import Dashboard from '../pages/dashboard/dashboard'
import StartupNameFoundersTeam from '../pages/startup-name-founders-team/startup-name-founders-team'
import StartupTeam from '../pages/startup-team/startup-team'
import LetMeFindStartup from '../pages/let-me-find-startup/let-me-find-startup'
import StartupList from '../pages/startup-list/startup-list'
import StartupName from '../pages/startup-name/startup-name'
import StartupTeamCoFounder from '../pages/startup-team-co-founder/startup-team-co-founder'

import { routes } from '../utils/constants'
import styles from './app.module.css'
import StartupJoin from '../pages/startup-join/startup-join'
import StartupJoinRequest from '../pages/startup-join-request/startup-join-request'
import { Box } from '@mui/material'
import Loader from '../components/loader/Loader'

type TelegramUser = {
  id: number;
  username: string;
  first_name?: string;
  last_name?: string;
  photo_url?: string;
};

function App(): JSX.Element {
  const location = useLocation()
  const isHomePath = location.pathname === routes.home
  const [loading, setLoading] = useState(false)
  const [userTelegramData, setUserTelegramData] = useState<TelegramUser | null>(
    null
  )

  useEffect(() => {
    setLoading(true)

    // test data for requests
    setUserTelegramData({
      id: 1,
      username: 'test'
    })
    localStorage.setItem(
      'telegramId',
      JSON.stringify(1)
    )
    localStorage.setItem(
      'telegramUserName',
      JSON.stringify('test')
    )

    // get real telegram data
    // @ts-ignore
    /*const tg = window.Telegram.WebApp
    tg.ready() // Telegram understand that Web App is ready
    setUserTelegramData(tg.initDataUnsafe.user || null)
    localStorage.setItem(
      'telegramId',
      JSON.stringify(tg.initDataUnsafe.user?.id)
    )
    localStorage.setItem(
      'telegramUserName',
      JSON.stringify(tg.initDataUnsafe.user?.username)
    )*/

    const timer = setTimeout(() => {
      setLoading(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (userTelegramData?.id) {
      setLoading(false)
    }
  }, [userTelegramData])

  return (
    <main className={styles.app}>
      {loading && <Loader />}
      {!loading && (
        <Box>
          {!isHomePath && <Header />}
          <section className={styles.section}>
            <Routes>
              <Route path={routes.home} element={<Register />} />
              <Route path={routes.selectRole} element={<SelectRole />} />
              <Route path={routes.founder} element={<Founder />} />
              <Route path={routes.coFounder} element={<CoFunder />} />
              <Route path={routes.coBuilder} element={<CoBuilder />} />
              <Route
                path={routes.letsStartYourStartup}
                element={<LetsStartYourStartup />}
              />
              <Route path={routes.yourStartup} element={<YourStartup />} />
              <Route path={routes.yourProduct} element={<YourProduct />} />
              <Route
                path={routes.yourCustomerTargetAudience}
                element={<YourCustomerTargetAudience />}
              />
              <Route path={routes.yourCustomer} element={<YourCustomer />} />
              <Route path={routes.yourMarket} element={<YourMarket />} />
              <Route path={routes.yourStartupLogo} element={<YourStartupLogo />} />
              <Route
                path={routes.yourStartupPitch}
                element={<YourSturtupPitch />}
              />
              <Route path={routes.dashboard} element={<Dashboard />} />
              <Route
                path={routes.startupNameFoundersTeam}
                element={<StartupNameFoundersTeam />}
              />
              <Route path={routes.startupTeam} element={<StartupTeam />} />
              <Route
                path={routes.letMeFindStartup}
                element={<LetMeFindStartup />}
              />
              <Route path={routes.startupList} element={<StartupList />} />
              <Route path={routes.startupName} element={<StartupName />} />
              <Route
                path={routes.startupTeamCoFounder}
                element={<StartupTeamCoFounder />}
              />
              <Route path={routes.startupJoin} element={<StartupJoin />} />
              <Route path={routes.startupJoinRequest} element={<StartupJoinRequest />} />
            </Routes>
          </section>
        </Box>
      )}
    </main>
  )
}

export default App
