import { FC } from 'react'
import Fab from '@mui/material/Fab'

type Props = {
  back?: boolean
  link: string
}

const RoundButton: FC<Props> = ({ back, link }) => {
  return (
    <div>
      {back ? (
        <Fab
          aria-label="back"
          sx={{
            width: 32,
            height: 32,
            minHeight: 0,
            bgcolor: 'var(--blue',
            '&:hover': {
              bgcolor: 'var(--blue)',
              boxShadow: 'none',
            },
          }}
        >
          <img
            src="/icons/ui-kit/arrow-left.svg"
            alt="Tag"
            width="6"
            height="12"
          />
        </Fab>
      ) : (
        <Fab
          href={link}
          aria-label="next"
          sx={{
            width: 32,
            height: 32,
            minHeight: 0,
            bgcolor: 'var(--blue)',
            '&:hover': {
              bgcolor: 'var(--blue)',
              boxShadow: 'none',
            },
          }}
        >
          <img
            src="/icons/ui-kit/arrow-right.svg"
            alt="Tag"
            width="6"
            height="12"
          />
        </Fab>
      )}
    </div>
  )
}

export default RoundButton
