import { configureStore } from '@reduxjs/toolkit'
import startupFormDataReducer from '../features/startupForm/slices/startupFormDataSlice'
import startupFormResultReducer from '../features/startupForm/slices/startupFormResultSlice'
import registrationFormResultReducer from '../features/registrationForm/slices/registrationFormResultSlice'

const store = configureStore({
  reducer: {
    startupFormData: startupFormDataReducer,
    startupFormResult: startupFormResultReducer,
    registrationFormResult: registrationFormResultReducer
  }
})

export default store

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
