import React from 'react'
import { HookSelect, useHookFormContext } from 'mui-react-hook-form-plus'
import { FormValues, SelectProps } from '../../utils/interfaces'
import { ReactComponent as CustomArrowIcon } from './Arrow-Down.svg'

const Select: React.FC<SelectProps> = ({ id, placeholder, required, options }) => {
  const { registerState } = useHookFormContext<FormValues>()
  const isDisabled = options.length === 0

  const labelWithAsterisk = (
    <>
      {placeholder} {required && <span style={{ color: 'red' }}>*</span>}
    </>
  )

  return (
    <HookSelect
      {...registerState(`selects.${id}`)}
      label={labelWithAsterisk}
      selectProps={{
        disabled: isDisabled,
        IconComponent: CustomArrowIcon,
        sx: {
          '& .MuiInputLabel-root': {
            color: '#273270'
          },
          '& .MuiOutlinedInput-root': {
            height: '60px'
          }
        }
      }}
      items={options}
      gridProps={{}}
      rules={{
        required: {
          value: required,
          message: 'Please select at least one'
        }
      }}
    />
  )
}

export default Select
