import { FC } from 'react'
import { Box, Typography } from '@mui/material'

type Props = {
  num: number
  step?: boolean
}

const CircleForNumbers: FC<Props> = ({ num, step }) => {
  const circleColor = step ? 'var(--green)' : 'transparent'
  return (
    <Box
      sx={{
        width: '48px',
        height: '48px',
        borderRadius: '50%',
        backgroundColor: circleColor,
        border: '4px solid var(--blue)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'var(--blue)',
      }}
    >
      <Typography variant="body1">{num}</Typography>
    </Box>
  )
}

export default CircleForNumbers
