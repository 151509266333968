import React from 'react'
import ItemStartupTeam from '../../components/itemStartupTeam/ItemStartupTeam'
import LogoRectangle from '../../components/logoRectangle/LogoRectangle'
import ActionButton from '../../components/actionButton/ActionButton'
import { routes } from '../../utils/constants'
import { useNavigate } from 'react-router-dom'

const StartupName = () => {
  const navigate = useNavigate()

  const handleFormSubmit = () => {
    //TODO
    navigate(routes.startupTeamCoFounder)
  }

  const handleStartupJoin = () => {
    //TODO
    navigate(routes.startupJoin)
  }
  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        marginTop: '20px',
      }}
    >
      <h1>Startup Name</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <ItemStartupTeam
          title={'Founder Name'}
          subtitle={'Product Management'}
        />
        <LogoRectangle logo={'logo'} />
      </div>
      <h3 style={{ width: '330px', textAlign: 'left', fontSize: '16px' }}>
        My startup __________Name_________(1) is developing _____Business
        Model____(2) to help ________TA_______________(3) solve
        _______problem___________(4) with ___________Solution__________(5) We
        compete in the growing____Startup industry_(6) market. <br /> We are
        similar to ____Competitor____(8.1) and _____Competitor_______(8.2){' '}
        <br /> but we ___Key Differentiator_(9).
      </h3>

      <ActionButton
        text={'Startup Team'}
        color="green"
        handleClick={handleFormSubmit}
      />
      <ActionButton text={'Startup Tokens'} color="green" />
      <ActionButton text={'Startup Track'} color="lightBlue" />
      <ActionButton 
        text={'Join!'} 
        handleClick={handleStartupJoin}
        />
    </section>
  )
}

export default StartupName
