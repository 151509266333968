import axios from 'axios'

const api = axios.create({
  baseURL: 'https://hat.5dhub.tech/api'
})

const request = async (method, endpoint, data = null, options = {}) => {
  try {
    if (!api) {
      throw new Error('API not created')
    }
    const telegramId = localStorage.getItem('telegramId')
    if (!telegramId || isNaN(telegramId)) {
      throw new Error('telegram_id is missing or not a numeric value in localStorage')
    }
    const response = await api({
      method,
      url: endpoint,
      data,
      headers: {
        Authorization: Number(telegramId),
        ...options.headers
      },
      ...options
    })
    return response
  } catch (error) {
    if (error.response?.status === 401) {
      console.error('Unauthorized. Please check your token or credentials.')
    } else {
      console.error(error)
    }
    throw error
  }
}
export const httpMethods = {
  get: (endpoint, options) => request('GET', endpoint, null, options),
  post: (endpoint, data, options) => request('POST', endpoint, data, options),
  put: (endpoint, data, options) => request('PUT', endpoint, data, options),
  delete: (endpoint, options) => request('DELETE', endpoint, null, options)
}

export default request
