import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/constants'
import ActionButton from '../../components/actionButton/ActionButton'
import LogoRectangle from '../../components/logoRectangle/LogoRectangle'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material'

const YourSturtupPitch: FC = () => {
  const navigate = useNavigate()
  const formData = useSelector((state: RootState) => state.startupFormResult)
  const yourStartupForm = useSelector(
    (state: RootState) => state.startupFormData.data?.pages?.yourStartupForm
  )
  /*const yourCustomerTargetAudienceForm = useSelector(
    (state: RootState) => state.startupFormData.data?.pages?.yourCustomerTargetAudienceForm
  )*/

  const businessModel = yourStartupForm?.fields
    ?.find((field) => field.id === 'business_model')
    ?.options?.find((option) => option.value === formData?.business_model)

  const industry = yourStartupForm?.fields
    ?.find((field) => field.id === 'industry')
    ?.options?.find((option) => option.value === formData?.industry)

  /*const targetAudience1: Option | undefined = yourCustomerTargetAudienceForm?.fields
    ?.find((field) => field.id === 'target_audiences_1')
    ?.options?.find((option) => option.value === formData?.target_audiences_1)*/

  console.log('Все данные из формы:', formData)

  const handleFormSubmit = () => {
    //TODO
    navigate(routes.dashboard)
  }

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px'
      }}
    >
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          gap: '20px',
          paddingLeft: '50px'
        }}
      >
        <h1>Your Startup Pitch</h1>
        <img src="/icons/startup-pitch-logo.svg" alt="Your Startup Pitch" />
      </div>
      <h2>Here you go!</h2>
      <p>
        Your first startup pitch that you will to communicate with investors.
      </p>
      <LogoRectangle
        logo={<img
          src={formData?.startup_logo_url}
          alt="Logo"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />}
        size="big" />
      <Stack
        sx={{
          width: '330px',
          textAlign: 'left',
          fontWeight: '400',
          fontSize: '16px',
          color: '#21242b'
        }}>
        <Typography display="block">
          My startup _<span style={{ color: '#494c8c' }}>{formData?.name || 'Startup Name'}</span>_ (1)
        </Typography>
        <Typography display="block">
          is developing _<span style={{ color: '#494c8c' }}>{businessModel?.label || 'Business Model'}</span>_ (2)
        </Typography>
        <Typography display="block">
          to help _<span style={{ color: '#494c8c' }}>{formData?.target_audiences || 'Target Audiences'}</span>_ (3)
        </Typography>
        <Typography display="block">
          solve _<span style={{ color: '#494c8c' }}>{formData?.problem_description || 'Problem Description'}</span>_
          (4)
        </Typography>
        <Typography display="block">
          with _<span style={{ color: '#494c8c' }}>{formData?.solution_description || 'Solution Description'}</span>_
          (5)
        </Typography>
        <Typography display="block">
          We compete in the growing _<span style={{ color: '#494c8c' }}>{industry?.label || 'Industry'}</span>_ (6)
          market.
        </Typography>
        <Typography display="block">
          We are similar to _<span style={{ color: '#494c8c' }}>{formData?.competitor_1 || 'Competitor 1'}</span>_
          (8.1)
        </Typography>
        <Typography display="block">
          and _<span style={{ color: '#494c8c' }}>{formData?.competitor_2 || 'Competitor 2'}</span>_ (8.2)
        </Typography>
        <Typography display="block">
          but we _<span style={{ color: '#494c8c' }}>{formData?.key_differentiator || 'Key Differentiator'}</span>_
          (9).
        </Typography>
      </Stack>
      <ActionButton handleClick={handleFormSubmit} text="Startup Dashboard" />
    </section>
  )
}

export default YourSturtupPitch
