import { FC } from 'react'
import Form from '../../components/form/Form'
import { FormConfig } from '../../utils/interfaces'

const CoBuilder: FC = () => {

  const config: FormConfig = {
    fields: [
      {
        id: 'linkedin',
        type: 'text',
        placeholder: 'LinkedIn Profile',
        required: false
      },
      {
        id: 'position',
        type: 'select',
        placeholder: 'Founder\'s Position',
        required: true,
        options: [
          { value: 'position1', label: 'Position 1' },
          { value: 'position2', label: 'Position 2' },
          { value: 'position3', label: 'Position 3' }
        ]
      },
      {
        id: 'language',
        type: 'multiselect',
        placeholder: 'Primary Language',
        required: true,
        options: [
          { value: 'english', label: 'English' },
          { value: 'spanish', label: 'Spanish' },
          { value: 'french', label: 'French' }
        ]
      },
      {
        id: 'softSkills',
        type: 'multiselect',
        placeholder: 'Soft Skills',
        required: true,
        options: [
          { value: 'soft1', label: 'Soft Skills 1' },
          { value: 'soft2', label: 'Soft Skills 2' },
          { value: 'soft3', label: 'Soft Skills 3' },
          { value: 'soft4', label: 'Soft Skills 4' }
        ]
      },
      {
        id: 'hardSkills',
        type: 'multiselect',
        placeholder: 'Hard Skills',
        required: true,
        options: [
          { value: 'hard1', label: 'Hard Skills 1' },
          { value: 'hard2', label: 'Hard Skills 2' },
          { value: 'hard3', label: 'Hard Skills 3' },
          { value: 'hard4', label: 'Hard Skills 4' }
        ]
      }
    ]
  }

  const handleFormSubmit = (data: any) => {
    console.log('Данные формы:', data)
    // Go to TRACKER
    window.location.href = 'https://t.me/Tracker_5D_Bot/mytracker'
  }

  return (
    <section style={{ marginTop: '16px' }}>
      <Form config={config} onSubmit={handleFormSubmit} />
    </section>
  )
}

export default CoBuilder
