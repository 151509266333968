import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import LogoWithCircles from '../../components/logoWithCircles/LogoWithCircles'
import ActionButton from '../../components/actionButton/ActionButton'
import { routes } from '../../utils/constants'
import StartupListFilter from './startup-list-filter/startup-list-filter'

const StartupList = () => {
  const [isFilter, setIsFilter] = useState(true)
  const navigate = useNavigate()

  const [cardData, setCardData] = useState<
    {
      id: string
      name: string
      industry: string
      short_description: string
      startup_logo_url: string
    }[]
  >([])

  const fetchData = async () => {
    try {
      const response = await fetch('https://hat.5dhub.tech/api/startups', {
        method: 'GET',
        headers: {
          Authorization: '1',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (response.ok) {
        const data = await response.json()
        setCardData(data)
      }
    } catch (error) {
      console.error('Произошла ошибка при получении данных:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleFormSubmit = () => {
    //TODO
    navigate(routes.startupName)
  }
  const handleFilter = () => {
    //TODO
    setIsFilter(!isFilter)
  }

  return (
    <>
      {isFilter ? (
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '50px',
              width: '335px',
            }}
          >
            <h1>Startup List</h1>
            <img
              style={{
                cursor: 'pointer',
              }}
              onClick={handleFilter}
              src="./icons/startup-list-filter.svg"
              alt="starup-filter"
            />
          </div>
          <TextField
            id="outlined-search"
            label="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                height: '44px',
              },
            }}
          />
          <div
            onClick={handleFormSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              cursor: 'pointer',
            }}
          >
            {cardData.map((item) => (
              <LogoWithCircles
                key={item.id}
                name={item.name}
                industry={item.industry}
                description={item.short_description}
                url={item.startup_logo_url}
              />
            ))}
          </div>
          <ActionButton handleClick={handleFormSubmit} />
        </section>
      ) : (
        <StartupListFilter handleFilter={handleFilter} />
      )}
    </>
  )
}

export default StartupList
