export const flattenObject = (obj: any): any => {
  let result: any = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      result = { ...result, ...flattenObject(value) }
    } else {
      result[key] = value
    }
  })

  return result
}

